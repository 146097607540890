<template>
    <v-container fluid class="p-4" style="margin-bottom:100px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row class="mt-5 mb-12">
        <v-col cols="12">
            <v-tabs
                v-model="tab"
                color="#1565c0"
                class="round_top"
                icons-and-text
                style="width: fit-content;margin-left: 25px;"
                height="46px"
            >
                <v-tab href="#tab-1">
                    Existing Customer
                </v-tab>

                <v-tab href="#tab-2">
                    New Customer
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="rounded-xl">
                <v-tab-item
                    value="tab-1"
                >
                    <v-card class="rounded-xl elevation-12 bg_datatable">
                        <v-card-title>
                            <v-row align="center" justify="center" style="margin-top: -16px;">
                                <v-col cols="12" xs="12" sm="6" md="4" style="background: #053d76;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                    <h4 class="text-white text-center" style="font-weight:700">
                                        Merchandiser
                                    </h4>
                                    <h5 class="text-white text-center">Form Isian Merchandiser</h5>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <validation-observer
                                ref="observer"
                                v-slot="{ invalid }"
                            >
                                <form @submit.prevent="submit">
                                    <v-container fluid>
                                        <v-row class="mt-5" justify="end">
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <h6 class="blue-lcd">Nama MD</h6>
                                                <v-text-field
                                                    solo
                                                    label="Nama MD"
                                                    persistent-hint
                                                    readonly
                                                    v-model="name_md"
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field> 
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <h6 class="blue-lcd">Kota</h6>
                                                <v-text-field
                                                    solo
                                                    label="Kota"
                                                    persistent-hint
                                                    v-model="city_md"
                                                    readonly
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field> 
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-lg-5">
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Nama Toko</h6>
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Nama Toko"
                                                    rules="required"
                                                >
                                                    <v-autocomplete
                                                        solo
                                                        v-model="name_mitra"
                                                        :items="items"
                                                        item-value="customer_id"
                                                        item-text="nama_customer"
                                                        :search-input.sync="search"
                                                        label="Nama Toko"
                                                        @change="(event) => updateCustomer(event)"
                                                        class="border-12"
                                                        :return-object="true"
                                                        :error-messages="errors"
                                                    ></v-autocomplete>
                                                </validation-provider>   
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Pemilik Toko</h6>
                                                <v-text-field
                                                    solo
                                                    label="Pemilik Toko"
                                                    persistent-hint
                                                    readonly
                                                    v-model="pemilik_mitra"
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field>   
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">No HP</h6>
                                                <v-text-field
                                                    solo
                                                    label="No HP"
                                                    persistent-hint
                                                    readonly
                                                    v-model="no_hp"
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field> 
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Alamat Toko</h6>
                                                <v-text-field
                                                    solo
                                                    label="Alamat Toko"
                                                    persistent-hint
                                                    readonly
                                                    v-model="address"
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field>   
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Kota</h6>
                                                <v-text-field
                                                    solo
                                                    label="Kota"
                                                    persistent-hint
                                                    readonly
                                                    v-model="city"
                                                    class="ma-0 pa-0 border-12"
                                                >
                                                </v-text-field>   
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-3">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Jenis Toko</h6>
                                                
                                                <div class="d-flex mt-0 pt-0">
                                                    <v-checkbox
                                                        v-model="type_mitra"
                                                        label="Toko Khusus Baja Ringan"
                                                        value="tkbj"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="type_mitra"
                                                        label="Toko Besi"
                                                        value="tb"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="type_mitra"
                                                        label="Toko Bahan Bangunan"
                                                        value="tbb"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>                                  
                                                </div>
                                            </v-col>
                                        </v-row>                                    
                                        <v-row class="mt-2">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">DATA  PRODUK</h6>
                                                <v-divider></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Produk yang Dijual</h6>
                                                <div class="d-flex mt-0 pt-0">
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Atap"
                                                        value="atap"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Floor Deck"
                                                        value="floor"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Kanal C"
                                                        value="kanal"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Reng"
                                                        value="reng"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Hollow"
                                                        value="hollow"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="product"
                                                        label="Lain lain"
                                                        value="lain"
                                                        class="mr-8 mt-0"
                                                        readonly
                                                    ></v-checkbox>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-2 pb-0">
                                                <h6 class="blue-lcd">MEREK PRODUK & BAHAN BAJA RINGAN</h6>
                                                <v-divider></v-divider>
                                                <v-simple-table
                                                    fixed-header
                                                    class="mb-5"
                                                >
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    <h6 class="blue-lcd">MEREK PRODUK</h6>
                                                                </th>
                                                                <th class="text-left">
                                                                    <h6 class="blue-lcd">BAHAN BAJA RINGAN</h6>
                                                                </th>
                                                                <th class="text-center">
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="(merk_product, index) in merk_products"
                                                                :key="index"
                                                            >
                                                                <td class="text-left">{{ merk_product.merk }}</td>
                                                                <td class="text-left">
                                                                    {{ merk_product.bahan }}
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn color="error" class="text-white border-12" height="40px" @click="deleteItem(merk_product, index)">
                                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <v-text-field
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="merk"
                                                                        hide-details=true
                                                                        class="mt-2 mb-2 border-12"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <div class="mt-2 mb-2 d-flex">
                                                                        <v-checkbox
                                                                            v-model="zinium_merk"
                                                                            label="Zinium"
                                                                            color="primary"
                                                                            value="Zinium"
                                                                            class="text_center mr-4 mb-3"
                                                                            hide-details
                                                                        ></v-checkbox>
                                                                        <v-checkbox
                                                                            v-model="zinium_merk"
                                                                            label="Lainnya"
                                                                            color="error"
                                                                            value="N"
                                                                            class="text_center mr-4 mb-3"
                                                                            hide-details
                                                                        ></v-checkbox>
                                                                        <v-text-field
                                                                            v-if="zinium_merk != 'Zinium' && zinium_merk != ''"
                                                                            solo
                                                                            persistent-hint
                                                                            v-model="bahan_bjlas"
                                                                            hide-details=true
                                                                            class="mt-2 mb-2 border-12"
                                                                        ></v-text-field>
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn color="primary" class="text-white border-12" height="40px" @click="addItem()">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-3">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">Omzet Toko per Bulan</h6>
                                                <v-divider></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Atap"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Atap ( meter )</h6>

                                                    <v-text-field
                                                        solo
                                                        label="Atap ( meter )"
                                                        persistent-hint
                                                        required
                                                        v-model="atap"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>    
                                                </validation-provider> 
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Floor Dek"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Floor Dek ( meter )</h6>

                                                    <v-text-field
                                                        solo
                                                        label="Floor Dek ( meter )"
                                                        persistent-hint
                                                        required
                                                        v-model="floor"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>   
                                                </validation-provider>  
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Kanal C"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Kanal C (batang)</h6>
                                                    <v-text-field
                                                        solo
                                                        label="Kanal C (batang)"
                                                        persistent-hint
                                                        required
                                                        v-model="kanal"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>  
                                                </validation-provider>   
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Reng"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Reng ( batang )</h6>
                                                    <v-text-field
                                                        solo
                                                        label="Reng ( batang )"
                                                        persistent-hint
                                                        required
                                                        v-model="reng"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field> 
                                                </validation-provider>    
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Hollow"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Hollow ( batang )</h6>
                                                    <v-text-field
                                                        solo
                                                        label="Hollow ( batang )"
                                                        persistent-hint
                                                        required
                                                        v-model="hollow"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field> 
                                                </validation-provider>    
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Omzet Lainnya"
                                                    rules="numeric" 
                                                >
                                                    <h6 class="blue-lcd">Lain-lain</h6>
                                                    <v-text-field
                                                        solo
                                                        label="Lain-lain"
                                                        persistent-hint
                                                        required
                                                        v-model="lain"
                                                        class="ma-0 pa-0 border-12"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>    
                                                </validation-provider> 
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">TOKO MENGETAHUI BAHAN BAJA RINGAN MEREK: ZINIUM</h6>
                                                
                                                <div class="d-flex mt-0 pt-0">
                                                    <v-checkbox
                                                        v-model="merk_zinium"
                                                        label="Tahu"
                                                        value="Y"
                                                        class="mr-8 mt-0"
                                                    ></v-checkbox>
                                                    <v-checkbox
                                                        v-model="merk_zinium"
                                                        label="Tidak Tahu"
                                                        value="N"
                                                        class="mr-8 mt-0"
                                                    ></v-checkbox>                                
                                                </div>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                <h6 class="blue-lcd">SOSIALISASI / KEGIATAN</h6>
                                                <div class="d-flex mt-2 pt-0">
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Sosialisasi Medsos & Hallo ZINIUM"
                                                            value="medsos"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>
                                                    </validation-provider>
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Sosialisasi Rejeki Toko ZINIUM (RTZ)"
                                                            value="rtz"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>  
                                                    </validation-provider>                                                      
                                                </div>
                                                <div class="d-flex mt-0 pt-0">
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Sosialisasi Santunan Tukang Baja Ringan (STBR)"
                                                            value="STBR"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>
                                                    </validation-provider>
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Sosialisasi Branding Kendaraan"
                                                            value="sbk"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>
                                                    </validation-provider>
                                                </div>
                                                <div class="d-flex mt-0 pt-0">
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Edukasi bahan ZINIUM"
                                                            value="edukasi"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>
                                                    </validation-provider>
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="KEGIATAN"
                                                        rules="required"
                                                    >
                                                        <v-checkbox
                                                            v-model="kegiatan"
                                                            label="Lainnya"
                                                            value="lain"
                                                            class="mr-8 mt-0"
                                                            :error-messages="errors"
                                                        ></v-checkbox>
                                                    </validation-provider>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mb-2">
                                            <v-col
                                                cols="12"
                                            >
                                                <h6 class="blue-lcd">Catatan</h6>
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Catatan"
                                                    rules="required"
                                                >
                                                <v-textarea
                                                    outlined
                                                    name="input-7-4"
                                                    label="Catatan"
                                                    v-model="remark"
                                                    :error-messages="errors"
                                                ></v-textarea>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-5 mb-2">
                                            <v-col 
                                                cols="12" style="margin-top: -30px;"
                                            >
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Catatan"
                                                    rules="required"
                                                >
                                                <v-file-input
                                                    solo
                                                    v-model="file"
                                                    label="Picture file"
                                                    small-chips
                                                    counter
                                                    multiple
                                                    show-size
                                                    append-icon="mdi-image"
                                                    class="border-12"
                                                    accept="image/*"
                                                    @change="handleFileUpload"
                                                    :error-messages="errors"
                                                ></v-file-input>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!-- <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Longitude"
                                                    rules="required"
                                                >
                                                <v-text-field
                                                    label="Longitude"
                                                    v-model="longitude"
                                                    disabled
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col> -->
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <v-text-field
                                                    label="Longitude"
                                                    v-model="longitude"
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Latitude"
                                                    rules="required"
                                                >
                                                <v-text-field
                                                    label="Latitude"
                                                    v-model="latitude"
                                                    disabled
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col> -->
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <v-text-field
                                                    label="Latitude"
                                                    v-model="latitude"
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Current Address"
                                                    rules="required"
                                                >
                                                <v-text-field
                                                    label="Current Address"
                                                    v-model="current_address"
                                                    disabled
                                                    :error-messages="errors"
                                                ></v-text-field>
                                                </validation-provider>
                                            </v-col> -->
                                            <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                <v-text-field
                                                    label="Current Address"
                                                    v-model="current_address"
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-btn block class="border-12" color="primary" @click="getLocation()">Get Current Location</v-btn>
                                            </v-col>
                                            <v-col cols="12">
                                                <div id="map_canvas" style="height:415px; width:100%; border: 1px solid grey;"></div>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" class="mt-lg-12">
                                            <v-col cols="12" xs="12" sm="6" md="2">
                                                <v-btn block class="rounded-l p-4 text-h6" color="#fff" @click="dialog = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="2">
                                                <v-btn block class="rounded-l text-white p-4 text-h6" color="#003871" type="submit" :disabled="invalid">Submit</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </form>
                            </validation-observer>
                            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                                <v-layout align-center pr-4>
                                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                    <v-layout column>
                                        <div>
                                        <strong>{{ snackbar.title }}</strong>
                                        </div>
                                        <div>{{ snackbar.text }}</div>
                                    </v-layout>
                                </v-layout>
                                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                                    <v-icon>clear</v-icon>
                                </v-btn>
                            </v-snackbar>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item
                    value="tab-2"
                >
                    <customer-visit-new />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import { required, digits, email, max, regex, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import CustomerVisitNew from './CustomerVisitNew.vue'

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} must be numeric',
})

export default {
    components:{
        CustomerVisitNew,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Merchandiser Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Customer Visit',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            merchandise: '',
            merchandises: [],
            sales: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            merchandisers: [],
            headers: [
                { text: 'TR date', value: 'tr_date' },
                { text: 'TR ID', value: 'tr_id' },
                { text: 'Merchandise', value: 'first_name' },
                { text: 'Customer', value: 'nama_customer' },
                { text: 'Owner', value: 'nama_owner' },
                { text: 'Address', value: 'alamat' },
                { text: 'Remark', value: 'remark' },
                { text: 'Activity', value: 'activity' },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            pagination: 1,
            dialog: false,
            name_mitra: '',
            pemilik_mitra: '',
            no_hp: '',
            address: '',
            city: '',
            type_mitra: '',
            merk_zinium: '',
            atap: '',
            floor: '',
            kanal: '',
            reng: '',
            hollow: '',
            lain: '',
            product: [],
            merk: '',
            bahan_bjlas: '',
            kegiatan: [],
            remark: '',
            longitude: '',
            latitude: '',
            current_address: '',
            mymap: '',
            md_id: '',
            tab: 'tab-1',
            name_md: '',
            city_md: '',
            uploadFiles: [],
            file: [],
            desc_file:'',
            search: null,
            items: [],
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            customer_id: '',
            merk_products: [],
            zinium_merk: ''
        }
    },
    mounted(){
        this.getShowMerchandise(this.$store.state.user.username)
    },
    methods:{    
        // getEnvConf(){
        //     let data = this.data_var;

        //     this.$store.dispatch("getVariabelUser", { data }).then(res => {

        //         if(res.data.data != null){
        //             for (let i = 0; i < res.data.data.length; i++) {

        //                 if (res.data.data[i]['var_id'] === 'MDID') {
        //                     if (res.data.data[i]['var_value'] != null) {
        //                         this.md_id = res.data.data[i]['var_value']
        //                         // console.log(this.md_id);
        //                         this.getShowMerchandise(this.md_id)
        //                     }
        //                 }

        //             }
        //         }

        //     })
        // },
        async getShowMerchandise(username){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/show?username=${username}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.md_id = res.data.md_id
                this.name_md = res.data.first_name + ' ' + res.data.last_name
                this.city_md = res.data.kota
            }).catch(err => {
                console.log(err.response);
            })
        },
        handleFileUpload(value){
            // this.file = value;
            // console.log(this.file);
        },
        getLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else { 
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please activate GPS",
                    visible: true
                };
            }
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/customer?search=${value}&md_id=${this.md_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        async updateCustomer(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/customer?customer_id=${value.customer_id}&md_id=${this.md_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                this.pemilik_mitra = res.data.data.nama_owner
                this.no_hp = res.data.data.no_hp
                this.address = res.data.data.alamat
                this.city = res.data.data.city
                this.customer_id = res.data.data.customer_id

                if (res.data.data.jenis_toko_bahan_bangunan == 'Y') {
                    this.type_mitra = 'tbb'
                } else if (res.data.data.jenis_toko_baja_ringan == 'Y') {
                    this.type_mitra = 'tkbj'
                } else if (res.data.data.jenis_toko_besi == 'Y') {
                    this.type_mitra = 'tb'
                }

                if (res.data.data.sold_product_atap == 'Y') {
                    this.product.push('atap')
                }
                if (res.data.data.sold_product_floor == 'Y') {
                    this.product.push('floor')
                }
                if (res.data.data.sold_product_hollow == 'Y') {
                    this.product.push('hollow')
                }
                if (res.data.data.sold_product_kanal == 'Y') {
                    this.product.push('kanal')
                }
                if (res.data.data.sold_product_reng == 'Y') {
                    this.product.push('reng')
                }
                if (res.data.data.sold_product_lain == 'Y') {
                    this.product.push('lain')
                }

                this.merk = res.data.data.merk_profil_baja_ringan
                this.bahan_bjlas = res.data.data.bahan_bjlas
                this.merk_zinium = res.data.data.merk_zinium

                this.merk_products = res.data.products

                this.showPositionExisting(res.data.data)

            });
        },
        async showPositionExisting(position){
            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }
            
            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.store_latitude}&lon=${position.store_longitude}`)
            .then(res => {
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;
                this.current_address = address
                this.longitude = res.data.lon
                this.latitude = res.data.lat

                this.mymap = L.map('map_canvas').setView([position.store_latitude, position.store_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([position.store_latitude, position.store_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br>'+address)
                    .openPopup();

            });
        },
        async showPosition(position){
            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }
            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
            .then(res => {
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;
                this.current_address = address
                this.longitude = res.data.lon
                this.latitude = res.data.lat

                this.mymap = L.map('map_canvas').setView([position.coords.latitude, position.coords.longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([position.coords.latitude, position.coords.longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br>'+address)
                    .openPopup();

                    });
        },
        clear(){
            this.file = []
            this.kegiatan = []
            this.product = []
            this.atap = ''
            this.floor = ''
            this.kanal = ''
            this.reng = ''
            this.hollow = ''
            this.lain = ''
            this.remark = ''
            this.type_mitra = ''
            this.name_mitra = ''
            this.pemilik_mitra = ''
            this.no_hp = ''
            this.address = ''
            this.city = ''
            this.merk = ''
            this.bahan_bjlas = ''
            this.latitude = ''
            this.longitude = ''
            this.current_address = ''
            this.merk_products = []
        },
        async submit(){

            if (this.md_id != '') {
                let formData = new FormData()
                formData.append('customerid', this.customer_id);
                formData.append('activities', this.kegiatan);
                formData.append('md_id', this.md_id);
                formData.append('atap', this.atap);
                formData.append('floor', this.floor);
                formData.append('kanal', this.kanal);
                formData.append('reng', this.reng);
                formData.append('hollow', this.hollow);
                formData.append('lain', this.lain);
                formData.append('remark', this.remark);
                formData.append('type_mitra', this.type_mitra);
                formData.append('nama_customer', this.name_mitra.nama_customer);
                formData.append('nama_owner', this.pemilik_mitra);
                formData.append('no_hp', this.no_hp);
                formData.append('alamat', this.address);
                formData.append('city', this.city);
                formData.append('merk_profil_baja_ringan', this.merk);
                formData.append('bahan_bjlas', this.bahan_bjlas);
                formData.append('lat', this.latitude ? this.latitude : '');
                formData.append('lon', this.longitude ? this.longitude : '');
                formData.append('current_address', this.current_address ? this.current_address : '');
                formData.append('merk_zinium', this.merk_zinium);

                for (var i = 0; i < this.kegiatan.length; i++) {
                    formData.append('activities[' + i + ']', this.kegiatan[i]);
                }
                
                for (var i = 0; i < this.product.length; i++) {
                    formData.append('products[' + i + ']', this.product[i]);
                }

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                formData.append('merk_products', JSON.stringify(this.merk_products));

                axios.post(`${process.env.VUE_APP_URL}/api/sr/merchandiser/store`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Add Data",
                        visible: true
                    };
                }).catch(err => {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response,
                        visible: true
                    };
                })
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Anda tidak punya akses sebagai merchandiser',
                    visible: true
                };
                return false
            }
            
        },
        addItem(){
            this.bahan_bjlas = this.zinium_merk === 'Zinium' ? 'Zinium' : this.bahan_bjlas

            this.merk_products.push(
                { 
                    id : this.merk_products.length + 1,
                    merk: this.merk,
                    bahan_bjlas: this.bahan_bjlas
                }
            );
            this.merk = ''
            this.bahan_bjlas = ''
            this.zinium_merk = ''
        },
        async deleteItem(merk, index){
            await axios.post(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/customer/delete`, {
                'customer_id': merk.customer_id,
                'id': merk.id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.merk_products.splice(index, 1);
            }).catch(err => {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        }
    },
    watch: {
        search (val) {
            val && val !== this.name_mitra && this.querySelections(val)
        },
    }
}
</script>